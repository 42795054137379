<template>
  <div class="statis">
    <el-table :data="list" style="width: 100%" border>
      <el-table-column prop="tempId" label="ID" align="center" width="80"></el-table-column>
      <el-table-column prop="time" label="日期" align="center"></el-table-column>
      <el-table-column prop="regCount" label="注册数" align="center">
        <template slot="header">
          注册数<noun-help content="当天注册的用户数"/>
        </template>
      </el-table-column>
      <el-table-column prop="oneVerify" label="实名数" align="center">
        <template slot="header">
          实名数<noun-help content="当天进行实名认证的用户数量"/>
        </template>
      </el-table-column>
      <el-table-column prop="oneVerify" label="实名率" align="center">
        <template slot="header">
          实名率<noun-help content="当天进行实名认证的用户数量/当天注册的用户数"/>
        </template>
        <template slot-scope="scope">
          {{ scope.row.oneVerify | getPercent(scope.row.regCount)}}
        </template>
      </el-table-column>
<!--      <el-table-column prop="assetsVerify" label="资产认证数1" align="center">-->
<!--        <template slot="header">-->
<!--          资产认证数<noun-help content="当天完成资产认证的用户数"/>-->
<!--        </template>-->
<!--      </el-table-column>-->
      <el-table-column prop="threeVerify" label="认证数" align="center">
        <template slot="header">
          认证数<noun-help content="当天完成3项认证的用户数"/>
        </template>
      </el-table-column>
      <el-table-column prop="threeVerify" label="认证率" align="center">
        <template slot="header">
          认证率<noun-help content="当天完成3项认证的用户数/当天注册的用户数"/>
        </template>
        <template slot-scope="scope">
          {{ scope.row.threeVerify | getPercent(scope.row.regCount)}}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
  import nounHelp from '@/components/noun-help'
  export default {
    components:{ nounHelp },
    props: {
      list: {
        type: Array,
        default: () => []
      }
    },
    filters: {
      getPercent(num,  total) {
        if (num == 0 || total == 0){
          return '-';
        }
        return ((num / total)* 100).toFixed(2) + '%';
      }
    }
  };
</script>
